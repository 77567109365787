.admin {
  padding: 0 0 50px 0;

  @media (min-width: 700px) {
    padding: 50px 100px;
    display: flex;
  }

  .container {
    padding: 0 20px;
  }
}
