header {
    // background-color: #ff996228;

    .nav {
        height: 15vh;
        border-bottom: 1.5px solid $orange;
        -webkit-box-shadow: 0px 8px 15px 5px rgba(0, 0, 0, 0.14);
        box-shadow: 0px 8px 15px 5px rgba(0, 0, 0, 0.14);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 15px 15px 5px 15px;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        background-color: white;
        z-index: 100;

        .container {

            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            figure {
                height: 12vh;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            ul {
                flex: 1;
                width: 100%;
                display: flex;
                justify-content: center;
                gap: clamp(15px, 5vw, 100px);
                margin: 15px 0;
                list-style: none;

                li {
                    a {
                        color: black;

                        font-size: clamp(14px, 5vw, 24px);
                        transition: all .2s linear;
                        cursor: pointer;

                        &:hover {
                            color: $orange;
                            transition: all .2s linear;
                        }
                    }
                }

                .active {
                    font-weight: bold;
                    color: $orange;
                }
            }
        }
    }

    .banner {
        width: 100%;
        margin-top: 15vh;

        img {
            width: 100%;
            height: auto;
            object-fit: cover;
        }
    }
}

@media (max-width: 800px) {

    $theme-color-basic: #202747;
    $theme-color-first: #28305a;
    $theme-color-second: #815CDE;
    $theme-color-third: #553568;
    /**Menu Burger**/
    $menu-burger-size: 40px;
    $menu-burger-line: 4px;
    $menu-burger-middle: $menu-burger-size/2 - $menu-burger-line/2;
    $menu-wrapper-size: 100px;

    header {
        position: relative;
        .banner {
            margin-top: 0;
        }
        .nav {
            position: relative;

            .container {


                .menu {
                    position: absolute;
                    transform: translate(-50%, -50%);
                    top: 50%;
                    left: 0;
                }

                .menu-burger {
                    width: $menu-burger-size;
                    height: $menu-burger-size;
                    border: none;
                    background: transparent;
                    position: relative;
                    right: - $menu-wrapper-size + $menu-burger-size * 1.5;

                    span,
                    &::after,
                    &::before {
                        content: '';
                        display: inline-block;
                        position: absolute;
                        width: $menu-burger-size;
                        height: $menu-burger-line;
                        background: black;
                        transition: all .2s ease-in-out;
                        left: 0;
                    }

                    span {
                        top: $menu-burger-middle;
                        left: $menu-burger-size/2 - ($menu-burger-size * 0.8)/2;
                        width: $menu-burger-size * 0.8;
                        background-color: $orange;
                    }

                    &::after {
                        top: $menu-burger-size - 2 * $menu-burger-line;
                    }

                    &::before {
                        top: $menu-burger-line;
                    }

                    // &:hover {
                    //     span {
                    //         left: $menu-burger-size/2 - ($menu-burger-size * 0.6)/2;
                    //         width: $menu-burger-size * 0.6;
                    //     }

                    //     &::after {
                    //         top: $menu-burger-size - 3 * $menu-burger-line;
                    //     }

                    //     &::before {
                    //         top: 2 * $menu-burger-line;
                    //     }
                    // }


                }

                ul {
                    display: none;
                }

                .activeMenu {
                    span {
                        left: $menu-burger-middle;
                        width: 0;
                    }

                    &::after {
                        top: $menu-burger-middle;
                        transform: rotate(45deg);
                    }

                    &::before {
                        top: $menu-burger-middle;
                        transform: rotate(-45deg);
                    }
                }

                .activeUl {
                    position: absolute;
                    top: 100%;
                    left: 0;
                    display: flex;
                    flex-direction: column;
                    background-color: white;
                    margin-top: 0;
                    padding: 20px;
                    border-top: 2px solid $orange;
                    border-bottom: 2px solid $orange;
                    -webkit-box-shadow: 0px 16px 28px -1px rgba(0, 0, 0, 0.38);
                    box-shadow: 0px 16px 28px -1px rgba(0, 0, 0, 0.22);
                }
            }
        }
    }
}

@media (min-width: 801px) {
    .menu {
        display: none;
    }
}