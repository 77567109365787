.blog {
    margin: 0 0 30px 0;
    padding: 15px;
}

.articles {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 30px 0;
    gap: 50px;

    article {
        text-align: center;
        width: 350px;
        min-width: 350px;
        max-width: 350px;
        flex: 1;
        background-color: white;
        padding: 15px;
        border-radius: 20px;
        color: black;
        min-height: 350px;
        display: grid;
        grid-template-rows: 150px 1fr 1fr;
        grid-template-columns: 1fr;
        align-items: center;
        justify-content: center;
        gap: 10px;
        border: 2px solid $orange;
        -webkit-box-shadow: 1px 1px 10px -1px #000000;
        box-shadow: 1px 1px 10px -1px $orange;

        figure {
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                max-width: 100%;
                object-fit: contain;
            }
        }
    }
}

.article {
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    max-width: 800px;
    margin: auto 15px;

    h2 {
        text-align: left !important;
        padding-left: 0 !important;
    }

    img {
        width: 100%;
        max-width: 600px;
        margin: auto;
        display: block;
    }
}

.button-to-blog {
    display: block;
    padding: 15px 0 0 15px;
    color: black;
    text-decoration: underline;
}

.articles_list {


    .pagination {
        width: max-content;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        button {
            background-color: $orange;
            border: unset;
            padding: 5px;
            width: 100px;
            color: white;

            &:disabled {
                opacity: .5;
                cursor: not-allowed;
            }
        }
    }
}

.search_article {
    width: max-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 15px;

    input {
        padding: 10px 20px;
        border: 2px solid $orange;
        border-radius: 10px;

        &:active,
        &:focus {
            outline: none !important;
        }
    }
}

// svg {
//     width: 100px;
//     height: 100px;
//     margin: 20px;
//     display: inline-block;
// }
