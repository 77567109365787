.home {

    .first-section,
    .second-section {
        padding: 15px;


        .first-section-text,
        .first-section-list,
        .second-section-text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding: 30px 0;
        }

        .first-section-list {
            padding-top: 0;
            width: 100%;
        }

        ul {
            list-style: none;

            li {
                h3 {
                    position: relative;
                    padding-left: 20px;
                    margin: 15px;

                    &::before {
                        content: "\2022";
                        position: absolute;
                        left: 0;
                    }
                }
            }
        }
    }
}

.second-section {
    figure {
        &:nth-of-type(1) {
            margin: 30px auto;

            width: clamp(250px, 30vw, 800px);

            img {
                width: 100%;
                height: auto;
            }
        }

        &:nth-of-type(2) {
            margin: 30px auto;

            width: clamp(150px, 20vw, 550px);

            img {
                width: 100%;
                height: auto;
            }
        }
    }

}

.third-section {
    padding: 15px;

    .services,
    .opinions {

        .services-list,
        .opinions-list {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            gap: 15px;
            margin: 30px 0;

            article {
                text-align: center;
                min-width: 300px;
                max-width: 300px;
                flex: 1;
                background-color: $orange;
                padding: 30px;
                border-radius: 20px;
                color: white;
                min-height: 350px;
                /*display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;*/
                display: grid;
                grid-template-rows: 150px .5fr 1fr 50px;
                align-items: center;
                justify-items: center;

                .devis-button {
                    padding: 10px 20px;
                    background-color: $secondary;
                    border-radius: 20px;
                    color: black;
                    font-weight: bold;

                }

                .devis-button-orange {
                    background-color: $orange;
                    color: white;
                }

                &:nth-of-type(2) {
                    background-color: $secondary;
                    color: black;
                }

                img {
                    height: 150px;
                    max-width: 250px;
                    width: auto;
                    margin: auto;
                }

                .service-item {
                    margin: 15px 0;

                    ul {
                        text-align: left;
                        display: flex;
                        flex-direction: column;
                        gap: 5px;
                    }
                }

                .price {
                    font-weight: bold;
                }
            }
        }
        .opinions-list{
            article{
                display: grid;
                grid-template-rows: 50px 250px 50px;
                justify-content: center;
                align-items: center;
                svg{
                    display: block;
                    margin: auto;
                }
            }
        }
    }

    .steps {
        margin-top: 30px;

        .steps-list {
            margin: 30px 0;
            display: flex;
            flex-wrap: wrap;
            gap: 20px;
            justify-content: center;
            align-items: center;

            .step {
                flex: 1;
                min-width: 300px;
                max-width: 300px;
                height: 450px;
                /*display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;*/
                display: grid;
                grid-template-rows: 150px .5fr 1fr;
                align-items: center;
                justify-items: center;
                gap: 15px;
                background-color: $orange;
                padding: 50px;
                border-radius: 20px;
                color: white;

                h3 {
                    text-transform: uppercase;
                }

                &:nth-of-type(1),
                &:nth-of-type(3) {
                    color: black;
                    background-color: $secondary;
                }

                img {
                    height: 150px;
                    width: auto;
                }
            }
        }
    }

    .opinions {
        h4 {
            text-align: center;
            font-style: italic;
        }

        article {

            &:nth-of-type(1),
            &:nth-of-type(3) {
                background-color: $secondary;
                color: black;

                svg {
                    color: $orange;
                    width: 50px;
                    height: 50px;
                }

                h5 {
                    color: $orange;
                    margin-top: 15px;
                    text-align: left;
                }
            }

            &:nth-of-type(2) {
                background-color: $orange !important;

                svg {
                    color: white;
                    width: 50px;
                    height: 50px;
                }

                h5 {
                    color: white;
                    margin-top: 15px;
                    text-align: left;
                }
            }
        }
    }
}