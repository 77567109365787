.add-article {
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    div {
      display: flex;
      flex-direction: column;
      gap: 10px;

      label {
        font-size: 1.2rem;
        font-weight: 600;
      }

      input {
        font-size: 1.2rem;
        font-weight: 600;
        border: 2px solid $orange;
        padding: 15px 30px;
        border-radius: 50px;

        &[type="file"] {
          border: none;
          padding: 0;
          background: none;
          cursor: pointer;
        }
      }

      textarea {
        font-size: 1.2rem;
        font-weight: 600;
      }
    }
  }
}