.about {
    margin: 30px 0;
    padding: 15px;

    .identity {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;

        figure {
            width: 300px;
            height: 300px;
            border: 5px solid $orange;
            border-radius: 50%;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        h1 {
            display: flex;
            flex-direction: column;
            color: $orange;

            span {
                color: black;
            }
        }
    }

    .about-text {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .realisations {
        margin: 30px 0;

        h2 {
            margin-bottom: 30px;
        }

        .realisations-list {
            display: flex;
            // flex-direction: column;
            flex-wrap: wrap;
            justify-content: center;
            gap: 50px;

            .realisation {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;
                // background-color: $orange;
                // color: white;
                padding: 15px;
                border-radius: 20px;
                width: 575px;
                text-align: center;

                // &:nth-of-type(1),
                // &:nth-of-type(4) {
                //     background-color: #d9d9d9;
                //     color: black;
                // }

                // @media (min-width:650px) {
                //     flex-direction: row;

                //     &:nth-of-type(1),
                //     &:nth-of-type(2) {
                //         flex-direction: row-reverse;
                //     }
                // }

                figure,
                iframe {

                    flex: 1;
                    max-height: 250px;
                    min-width: 200px;
                    max-width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: contain;
                    }
                }

                iframe {
                    // height: 500px;
                    width: 100%;
                }

                .infos {
                    flex: 1;
                    // min-width: 300px;
                    display: flex;
                    flex-direction: column;
                    gap: 5px;

                    @media (min-width:650px) {

                        &:nth-of-type(2),
                        &:nth-of-type(4) {
                            order: 1;
                        }
                    }
                }
            }
        }
    }
}