.newsletter_list,.views {
  table{
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
    border: 1px solid #ddd;
    margin: 0 auto;
    margin-top: 20px;
    margin-bottom: 20px;
    thead{
      background-color: #f2f2f2;
      tr{
        th{
          padding: 8px;
          text-align: left;
          border: 1px solid #ddd;
        }
      }
    }
    tbody{
      tr{
        &:nth-of-type(even){
          background-color: #f2f2f2;
        }
        td{
          padding: 8px;
          text-align: left;
          border: 1px solid #ddd;
        }
      }
    }
  }

  .views_list{
    p{
      margin: 15px 0;
    }
  }
}

.search{
    margin: 20px auto;
    width: 90%;
    max-width: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    input{
        width: 100%;
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        outline: none;
        &:focus{
        border: 1px solid #000;
        }
    }
    button{
        padding: 10px;
        border: 1px solid #ddd;
        border-radius: 5px;
        background-color: #ddd;
        cursor: pointer;
        transition: .3s linear all;
        &:hover{
        background-color: #000;
        color: #fff;
        }
    }
}