.contact {
    margin: 0 0 30px 0;
    padding: 15px;

    .contact-methods {
        text-align: center;

        a {
            color: black;
        }

        .contact-methods-items {
            margin: 30px 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 50px;

            article {
                width: 250px;
                display: grid;
                grid-template-rows: 200px 1fr;
                justify-content: center;
                align-items: center;
                gap: 15px;

                figure {
                    width: 200px;
                    margin: auto;

                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                h3 {
                    align-self: end;
                }
            }
        }
    }

    .contact-form {
        .form-error {
            width: 100%;
            text-align: center;

            p {
                margin: auto;
                padding: 10px 20px;
                background-color: red;
                color: white;
            }

        }

        form {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: center;
            gap: 100px;
            margin-top: 25px;

            .contact-form-item {
                // flex: 1;
                width: 550px;
                display: flex;
                flex-direction: column;
                gap: 15px;
                position: relative;

                label {
                    margin-left: 15px;
                    position: absolute;
                    bottom: 5px;
                    transition: all linear .2s;
                    pointer-events: none;
                    opacity: 0;
                    color: $orange;
                    font-weight: bold;
                }


                input,
                select,
                textarea {
                    padding: 5px;
                    border: none;
                    border-bottom: 2px solid $orange;
                    // border-radius: 20px;
                    background-color: rgba(228, 228, 228, 0.139);

                    &:active+label,
                    &:focus+label {
                        bottom: 100%;
                        margin-left: 0;
                        opacity: 1;
                    }

                    &:active,
                    &:focus {
                        outline: none !important;
                    }

                    // &::placeholder {
                    //     display: none;
                    //     color: transparent;
                    // }
                }

                textarea {

                    &:active+label,
                    &:focus+label {
                        bottom: 100%;

                    }
                }

                svg {
                    position: absolute;

                    bottom: 3px;
                    right: 5px;
                }

            }

            #textarea {
                width: 100%;
            }

            textarea {
                resize: none;
            }

            button {
                color: white;
                padding: 20px 40px;
                font-size: clamp(12px, 8vw, 18px);
            }

            .loading-form {
                color: $orange;
                font-weight: bold;
            }

            .rgpd-container {
                width: 100%;
                display: flex;
                gap: 10px;
            }

            #bait {
                display: none;
            }
        }
    }
}