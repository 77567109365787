
.admin_header {
  padding: 20px 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);

  nav {
    ul {
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      text-align: center;
      gap: 15px;

      li {
        a {
          color: #000;
          text-decoration: none;
          font-weight: 600;
          font-size: 1.2rem;
          transition: .3s linear all;
          display: flex;
          gap: 10px;
          align-items: center;

          &:hover {
            color: $orange;
          }
        }
      }

    }
  }
}

@media (min-width: 700px) {
  .admin_header {
    width: 50px;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 1000000;
    display: flex;
    align-items: center;
    transition: .3s linear all;
    overflow: hidden;
    background-color: $orange;

    &:hover {
      width: 200px;
      transition: .3s linear all;


      span {
        transition: .5s linear all;
      }
    }

    ul {
      display: flex;
      flex-direction: column;
      align-items: unset;
      gap: 50px;
      text-align: left;

      li {
        margin-left: 5px;

        a {
          color: white !important;
        }

        svg {
          width: 30px;
          height: 30px;
        }

        span {
          margin-left: 10px;
          transition: .5s linear all;
        }
      }
    }
  }
}