
.newsletter {
    padding: 15px;

    .container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        justify-content: center;
        align-items: center;
        text-align: center;

        .newsletter_container {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;
            gap: 50px;
            width: 100%;

            figure {
                width: 100%;
                max-width: 500px;

                img {
                    width: 100%;
                    height: 100%;
                    max-height: 250px;
                }
            }

            form {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                gap: 15px;
                width: 100%;
                max-width: 500px;

                div {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    gap: 5%;

                    label {
                        margin-left: 15px;
                        position: absolute;
                        bottom: 5px;
                        transition: all linear .2s;
                        pointer-events: none;
                        opacity: 0;
                        color: $orange;
                        font-weight: bold;
                    }

                    input {
                        width: 70%;
                        padding: 10px;
                        padding: 5px;
                        border: none;
                        border-bottom: 2px solid $orange;
                        // border-radius: 20px;
                        background-color: rgba(228, 228, 228, 0.139);

                        &:active,
                        &:focus {
                            outline: none !important;
                        }
                    }

                    button {
                        width: 25%;
                        border: unset;
                        background-color: $orange;
                        color: white;
                        transition: all .3s linear;
                        border: 2px solid transparent;
                        border-radius: 5px;
                        padding: 5px;
                        text-align: center;

                        &:hover {
                            background-color: white;
                            color: $orange;
                            transition: all .3s linear;
                            border: 2px solid $orange;
                        }
                    }
                }
            }
        }
        .newsletter_success{
            text-align: center;
            color: green;
            padding: 15px;
        }
        .newsletter_error{
            text-align: center;
            color: red;
            padding: 15px;
        }
    }
}