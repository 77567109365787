.login {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  
    .card {
      width: 300px;
      background-color: #fff;
      padding: 20px;
      border-radius: 10px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      text-align: center;
  
      img {
        margin: auto !important;
      }
    }
  
    h2 {
      margin-bottom: 20px;
    }
  
    form {
      display: flex;
      flex-direction: column;
    }
  
    label {
      text-align: left;
      margin-bottom: 5px;
    }
  
    input {
      padding: 10px;
      margin-bottom: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
    }
  
    button {
      margin-top: 15px !important;
      padding: 10px;
     background-color: $orange;
      color: #fff;
      border: none;
      border-radius: 5px;
      cursor: pointer;
    }
  
  }
  